<template>
    <inner-page-card route-path="/manage-users/1/" heading="Add Bank e Auctions India.com Admin"
                     :link-back="true" :scrollbar="true">

        <s-form class="row" @submit="onSubmit" ref="formValidator">

            <div class="col-6">
                <validated-input label="Username*" name="Username" placeholder="Username" class="c-form-input"
                                 v-model="model.username" :rules="{required:true}"
                                 @blur="checkUsername(model.username)"/>
            </div>

            <div class="col-6">
                <validated-input label="Employee ID*" placeholder="Example: ENP576281" class="c-form-input"
                                 v-model="model.employee_number" name="Employee ID"
                                 :rules="{required:true}"
                ></validated-input>
            </div>

            <div class="col-6">
                <validated-input label="First Name*" placeholder="First Name" class="c-form-input"
                                 v-model="model.first_name" name="First Name"
                                 :rules="{required:true}"
                ></validated-input>
            </div>

            <div class="col-6">
                <validated-input label="Last Name*" placeholder="Last Name" class="c-form-input"
                                 v-model="model.last_name" name="Last Name"
                                 :rules="{required:true}"
                ></validated-input>
            </div>

            <div class="col-6">
                <validated-input label="Mobile Number*" placeholder="Mobile number" class="c-form-input"
                                 v-model="model.mobile_number" type="number" name="Mobile number"
                                 :rules="{required:true, min:10, max:10}" @blur="checkMobile(model.mobile_number)"/>
            </div>

            <div class="col-6">
                <validated-input label="E-Mail ID*" placeholder="E-Mail ID" type="email"
                                 class="c-form-input" name="E-Mail ID"
                                 v-model="model.email" @blur="checkEmailExists(model.email)"
                                 :rules="{required:rules.email}"/>
            </div>

            <div class="col-12">
                <validated-input label="Role*" placeholder="Example: Database Manager" class="c-form-input"
                                 v-model="model.employee_role"
                                 :rules="{required:true}"
                ></validated-input>
            </div>

            <div class="col-12">
                <div class="fl-x fl-j-c">
                    <btn text="Cancel" type="button" class="px-5" @click="$router.push({path: '/manage-users/0/'})"
                         design="basic-b" color="secondary"></btn>

                    <btn text="Add" loading-text="Adding..." :loading="loading" class="px-5 ml-3" design="basic-a"
                         color="secondary"></btn>
                </div>
            </div>

        </s-form>

        <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="SuccessModal" width="25r"
               header-color="">

            <div class="text-center">
                <div class="mb-4">
                    <img src="../../../assets/web/homee/icons/success-tick.png" class="icon-img-md" alt="">
                </div>

                <h4 class="mb-4 font-inter-bold">User Added</h4>

                <p class="font-inter-light mb-5">User <span v-html="model.first_name + ' ' + model.last_name"></span>
                    has been added as Bank e Auction India Admin.</p>

                <btn text="Done" class="" block
                     @click="$router.push({ path : '/manage-users/1/' });"></btn>
            </div>

        </modal>

    </inner-page-card>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'AddVenturaAdmin',

    data () {
        return {
            loading : false,

            model : {
                username        : '',
                first_name      : '',
                last_name       : '',
                employee_role   : '',
                employee_number : '',
                mobile_number   : '',
                email           : ''
            },
            rules : {
                email : {
                    required : true
                }
            },

            userId : ''
        };
    },

    methods : {

        async onSubmit () {
            const formValidator = this.$refs.formValidator;
            this.loading = true;
            const response = await axios.form(urls.userList.venturaAdmin.addEdit, this.model);
            if (response.data.success) {
                this.loading = false;
                this.userId = response.data.id;
                await this.$refs.SuccessModal.show();
            } else {
                formValidator.setErrors(response.data.errors);
                this.errorMessage = response.data.errors.__all__ || '';
                this.loading = false;
            }
        },

        checkUsername (username) {
            const that = this;
            const data = {
                username : username
            };

            axios.form(urls.userList.usernameCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    that.$refs.formValidator.setErrors({ Username : json.message });
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        checkEmailExists (email) {
            const that = this;
            const data = {
                email : email
            };
            axios.form(urls.userList.emailCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    if (!that.invalidEmail) {
                        that.$refs.formValidator.setErrors({ email : json.message });
                    }
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        checkMobile (mobile) {
            const that = this;
            const data = {
                mobile : mobile
            };

            axios.form(urls.userList.mobileCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    that.$refs.formValidator.setErrors({ 'Mobile number' : json.message });
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        }
    }
};
</script>

<style scoped>

</style>
